import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35ff2b11"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-card" }
const _hoisted_2 = { class: "main-card__block" }
const _hoisted_3 = { class: "main-card__img" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "main-card__info-block" }
const _hoisted_6 = { class: "main-card__name" }
const _hoisted_7 = { class: "main-card__skils-block" }
const _hoisted_8 = { class: "main-card__skils-block-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.imgCatalog,
          alt: "main-card"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.name), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(item.trait_type), 1),
              _createElementVNode("div", null, _toDisplayString(item.value), 1)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}